<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content bgFFF">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? (disabled ? "查看" : "修改") : "添加" }}路由规则
      </h2>
      <!--搜索条件区域-->
      <div>
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 330px"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.Rule_Name')" prop="ruleName">
            <el-input
              :maxlength="16"
              :disabled="disabled"
              v-model.trim="formInline.ruleName"
              placeholder="请输入规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Rule_label')" prop="ruleTag">
            <el-input
              :maxlength="64"
              :disabled="disabled"
              v-model.trim="formInline.ruleTag"
              placeholder="请输入规则标签"
            ></el-input>
          </el-form-item>
          <el-form-item label="请求地址" prop="requestUrl">
            <el-input
              :maxlength="64"
              :disabled="disabled"
              v-model.trim="formInline.requestUrl"
              placeholder="请输入请求地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="回调地址">
            <el-input
              :maxlength="64"
              :disabled="disabled"
              v-model.trim="formInline.callbackUrl"
              placeholder="请输入回调地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="tokenUrl">
            <el-input
              :maxlength="64"
              :disabled="disabled"
              v-model.trim="formInline.tokenUrl"
              placeholder="请输入tokenUrl"
            ></el-input>
          </el-form-item>
          <el-form-item label="处理器" prop="cpudata">
            <el-input
              :maxlength="64"
              :disabled="disabled"
              v-model.trim="formInline.cpudata"
              placeholder="请输入处理器"
            ></el-input>
          </el-form-item>
          <el-form-item label="accessKey">
            <el-input
              :maxlength="32"
              :disabled="disabled"
              v-model.trim="formInline.accessKey"
              placeholder="请输入accessKey"
            ></el-input>
          </el-form-item>
          <el-form-item label="secret">
            <el-input
              :maxlength="32"
              :disabled="disabled"
              v-model.trim="formInline.secret"
              placeholder="请输入secret"
            ></el-input>
          </el-form-item>
          <el-form-item label="规则状态" prop="sex">
            <el-radio-group
              v-model.trim="formInline.ruleState"
              :disabled="disabled"
              filterable
              placeholder=""
            >
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')">
            <el-input
              :maxlength="64"
              :disabled="disabled"
              v-model.trim="formInline.remark"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Route_type')" prop="pdaManagerRole">
            <el-select
              v-model.trim="formInline.ruleType"
              filterable
              size="12"
              :disabled="disabled"
            >
              <el-option label="请选择" value></el-option>
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in urlTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="对应车场"
            style="width: 700px"
            v-show="formInline.ruleType == 1"
          >
            <el-transfer
              :titles="['未选择', '已选择']"
              filterable
              :props="{
                key: 'parkId',
                label: 'parkName',
              }"
              filter-placeholder="请输入路由类型"
              v-model="selected"
              :data="parkList"
            >
            </el-transfer>
            <div class="el-form-item__error" v-if="isSelected">
              请选择一个规则
            </div>
          </el-form-item>
          <el-form-item
            label="对应运营商"
            style="width: 700px"
            v-show="formInline.ruleType == 3"
          >
            <el-transfer
              :titles="['未选择', '已选择']"
              filterable
              :props="{
                key: 'operationId',
                label: 'operationName',
              }"
              filter-placeholder="请输入路由类型"
              v-model="selecteoperation"
              :data="operationist"
            >
            </el-transfer>
            <div class="el-form-item__error" v-if="isSelected">
              请选择一个规则
            </div>
          </el-form-item>
          <el-form-item
            label="对应区域"
            style="width: 700px"
            v-show="formInline.ruleType == 2"
          >
            <el-transfer
              :titles="['未选择', '已选择']"
              filterable
              :props="{
                key: 'areaId',
                label: 'areaName',
              }"
              filter-placeholder="请输入路由类型"
              v-model="selecteArea"
              :data="AreaList"
            >
            </el-transfer>
            <div class="el-form-item__error" v-if="isSelected">
              请选择一个规则
            </div>
          </el-form-item>
          <el-form-item
            label="自定义属性说明"
            v-show="formInline.ruleType == 4"
          >
            <el-input
              type="textarea"
              :rows="5"
              :disabled="disabled"
              maxlength="1000"
              show-word-limit
              v-model.trim="formInline.textarea"
              placeholder="请输入自定义属性"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: left; margin-left: 8.5%; margin-top: 40px">
          <el-button
            type="primary"
            v-if="!disabled"
            @click="submitData"
            style="width: 88px"
            >提交</el-button
          >
          <el-button type="" @click="$router.go(-1)" style="width: 88px"
            >取消</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import //  dateFormat
"@/common/js/public.js";
export default {
  name: "hello",
  data() {
    return {
      // 0:通用规则,,1车场2:区域,3:运营商,4:设备
      disabled: false,
      urlTypeList: [],
      isSelected: false, // 判断选择车场
      selected: [],
      parkList: [],
      selecteArea: [],
      AreaList: [],
      selecteoperation: [],
      operationist: [],
      isEdit: false,
      edrsRuleId: "",
      formInline: {
        ruleName: "",
        ruleTag: "",
        requestUrl: "",
        callbackUrl: "",
        tokenUrl: "",
        cpudata: "",
        accessKey: "",
        secret: "",
        ruleState: "",
        remark: "",
        ruleType: "",
        textarea: "",
      },
      rules: {
        ruleName: [
          {
            required: true,
            message: "请输入规则名称",
            trigger: "blur",
          },
        ],
        ruleTag: [
          {
            required: true,
            message: "请输入规则标签",
            trigger: "blur",
          },
        ],
        requestUrl: [
          {
            required: true,
            message: "请输入请求地址",
            trigger: "blur",
          },
        ],
        cpudata: [
          {
            required: true,
            message: "请输入处理器",
            trigger: "blur",
          },
        ],
      },
      showParkingList: [],
      showSelect: [],
    };
  },
  methods: {
    // 查看
    getLookRuletype(val) {
      let url = "/acb/2.0/edrsRule/" + this.edrsRuleId;
      this.$axios.get(url, { data: {} }).then((res) => {
        this.showDetails(res.value);
      });
    },
    showDetails(res) {
      this.formInline.ruleName = res.ruleName;
      this.formInline.ruleTag = res.ruleTag;
      this.formInline.requestUrl = res.url;
      this.formInline.callbackUrl = res.callbackUrl;
      this.formInline.tokenUrl = res.tokenUrl;
      this.formInline.cpudata = res.processor;
      this.formInline.accessKey = res.accessKey;
      this.formInline.secret = res.secret;
      this.formInline.ruleState = res.ruleState;
      this.formInline.remark = res.remark;
      this.formInline.ruleType = res.ruleType;
      if (this.formInline.ruleType == "1") {
        this.selected = res.ruleIds.split(",");
      } else if (this.formInline.ruleType == "2") {
        this.selecteArea = res.ruleIds.split(",");
      } else if (this.formInline.ruleType == "3") {
        this.selecteoperation = res.ruleIds.split(",");
      } else if (this.formInline.ruleType == "4") {
        this.formInline.textarea = res.ruleIds;
      } else {
      }
    },
    // 获取路由类型
    getRuletype(val) {
      let url = "/acb/2.0/edrsRule/droplist";
      this.$axios.get(url, { data: {} }).then((res) => {
        this.urlTypeList = res.value;
      });
    },
    setDataDisabled(res) {
      res.map((item) => {
        item.disabled = this.disabled;
      });
    },
    // 查询路由规则列表
    queryRuleList(val) {
      let url = "/acb/2.0/park/currentUserParkList";
      this.$axios
        .get(url, {
          data: {
            // 原参数"2"  后端说是bug修改成"0,2" 加参数dataSource=2
            slaveRelations: "0,2",
            dataSource: "2",
          },
        })
        .then((res) => {
          this.parkList = res.value;
          this.setDataDisabled(res.value);
        });
    },
    // 根据路由类型查区域
    queryAeraList(val) {
      let url = "/acb/2.0/area/getAllArea";
      this.$axios.get(url, { data: {} }).then((res) => {
        this.AreaList = res.value[0].childrenAreas;
      });
    },
    // 根据路由类型查运营商
    queryOperationList(val) {
      let url = "/acb/2.0/operation/nameList";
      this.$axios.get(url, { data: {} }).then((res) => {
        this.operationist = res.value;
      });
    },
    // 添加路由规则
    addruleList(ruleIds) {
      let data = {
        edrsRuleId: this.edrsRuleId,
        ruleName: this.formInline.ruleName,
        ruleTag: this.formInline.ruleTag,
        url: this.formInline.requestUrl,
        callbackUrl: this.formInline.callbackUrl,
        tokenUrl: this.formInline.tokenUrl,
        processor: this.formInline.cpudata,
        accessKey: this.formInline.accessKey,
        secret: this.formInline.secret,
        ruleState: this.formInline.ruleState,
        remark: this.formInline.remark,
        ruleType: this.formInline.ruleType,
        ruleIds: ruleIds,
        content: "",
      };
      console.log("-->data", data);
      let url = this.isEdit
        ? "/acb/2.0/edrsRule/update"
        : "/acb/2.0/edrsRule/add";
      let method = "post";
      this.$axios[method](url, {
        data: data,
      }).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.$router.go(-1);
      });
    },
    getCheckedIds() {
      let res = [];
      this.showParkingList.map((item) => {
        item.map((subItem) => {
          if (subItem.checked) {
            res.push(subItem.parkId);
          }
        });
      });
      return res;
    },
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formInline.ruleType == "1" && this.selected.length == 0) {
            this.$alert("请选择车场！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (this.formInline.ruleType == "2" && this.selecteArea.length == 0) {
            this.$alert("请选择区域！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (
            this.formInline.ruleType == "3" &&
            this.selecteoperation.length == 0
          ) {
            this.$alert("请选择运营商！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (
            this.formInline.ruleType == "4" &&
            this.formInline.textarea.length == 0
          ) {
            this.$alert("请填写自定义属性！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          if (this.formInline.ruleType == "1") {
            this.addruleList(this.selected.join(","));
          } else if (this.formInline.ruleType == "2") {
            this.addruleList(this.selecteArea.join(","));
          } else if (this.formInline.ruleType == "3") {
            this.addruleList(this.selecteoperation.join(","));
          } else if (this.formInline.ruleType == "4") {
            this.addruleList(this.formInline.textarea);
          } else {
            this.addruleList("");
          }
        } else {
          return false;
        }
      });
    },
  },
  components: {},
  created() {
    if (this.$route.query.data) {
      this.edrsRuleId = this.$route.query.data.edrsRuleId;
      this.isEdit = true;
      this.getLookRuletype();
      if (this.$route.query.data.showTag) {
        this.disabled = true;
      }
    }
  },
  mounted() {
    this.getRuletype();
    this.queryRuleList();
    this.queryAeraList();
    this.queryOperationList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
}
</style>
<style scoped>
.chosePark {
  float: right;
  margin-right: -70px;
  margin-top: -52px;
}
.showParkListBox {
  display: flex;
}
.parentName {
  margin-right: 10px;
  text-align: right;
}
.showChildBox {
  display: flex;
}
.checkBox {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.checkLabel {
  max-width: 182px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.labelDisabled {
  color: #c0c4cc;
  cursor: not-allowed;
}
.labelChecked {
  color: #0f6eff;
}
.selected >>> .el-form-item__label:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>
