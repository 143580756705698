var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content bgFFF" }, [
      _c("h2", { staticClass: "title" }, [
        _c("div", { staticClass: "title_icon" }),
        _vm._v(
          " " +
            _vm._s(_vm.isEdit ? (_vm.disabled ? "查看" : "修改") : "添加") +
            "路由规则 "
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "330px" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Rule_Name"),
                    prop: "ruleName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 16,
                      disabled: _vm.disabled,
                      placeholder: "请输入规则名称",
                    },
                    model: {
                      value: _vm.formInline.ruleName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "ruleName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.ruleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Rule_label"),
                    prop: "ruleTag",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 64,
                      disabled: _vm.disabled,
                      placeholder: "请输入规则标签",
                    },
                    model: {
                      value: _vm.formInline.ruleTag,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "ruleTag",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.ruleTag",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请求地址", prop: "requestUrl" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 64,
                      disabled: _vm.disabled,
                      placeholder: "请输入请求地址",
                    },
                    model: {
                      value: _vm.formInline.requestUrl,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "requestUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.requestUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "回调地址" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 64,
                      disabled: _vm.disabled,
                      placeholder: "请输入回调地址",
                    },
                    model: {
                      value: _vm.formInline.callbackUrl,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "callbackUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.callbackUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "tokenUrl" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 64,
                      disabled: _vm.disabled,
                      placeholder: "请输入tokenUrl",
                    },
                    model: {
                      value: _vm.formInline.tokenUrl,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "tokenUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.tokenUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理器", prop: "cpudata" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 64,
                      disabled: _vm.disabled,
                      placeholder: "请输入处理器",
                    },
                    model: {
                      value: _vm.formInline.cpudata,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "cpudata",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.cpudata",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "accessKey" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 32,
                      disabled: _vm.disabled,
                      placeholder: "请输入accessKey",
                    },
                    model: {
                      value: _vm.formInline.accessKey,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "accessKey",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.accessKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "secret" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 32,
                      disabled: _vm.disabled,
                      placeholder: "请输入secret",
                    },
                    model: {
                      value: _vm.formInline.secret,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "secret",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.secret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规则状态", prop: "sex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        filterable: "",
                        placeholder: "",
                      },
                      model: {
                        value: _vm.formInline.ruleState,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "ruleState",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.ruleState",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.remarks") } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 64,
                      disabled: _vm.disabled,
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.formInline.remark,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "remark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Route_type"),
                    prop: "pdaManagerRole",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        size: "12",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.formInline.ruleType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "ruleType",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.ruleType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "请选择", value: "" },
                      }),
                      _vm._l(_vm.urlTypeList, function (value) {
                        return _c("el-option", {
                          key: value.code,
                          attrs: { label: value.desc, value: value.code },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.ruleType == 1,
                      expression: "formInline.ruleType == 1",
                    },
                  ],
                  staticStyle: { width: "700px" },
                  attrs: { label: "对应车场" },
                },
                [
                  _c("el-transfer", {
                    attrs: {
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      props: {
                        key: "parkId",
                        label: "parkName",
                      },
                      "filter-placeholder": "请输入路由类型",
                      data: _vm.parkList,
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                  _vm.isSelected
                    ? _c("div", { staticClass: "el-form-item__error" }, [
                        _vm._v(" 请选择一个规则 "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.ruleType == 3,
                      expression: "formInline.ruleType == 3",
                    },
                  ],
                  staticStyle: { width: "700px" },
                  attrs: { label: "对应运营商" },
                },
                [
                  _c("el-transfer", {
                    attrs: {
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      props: {
                        key: "operationId",
                        label: "operationName",
                      },
                      "filter-placeholder": "请输入路由类型",
                      data: _vm.operationist,
                    },
                    model: {
                      value: _vm.selecteoperation,
                      callback: function ($$v) {
                        _vm.selecteoperation = $$v
                      },
                      expression: "selecteoperation",
                    },
                  }),
                  _vm.isSelected
                    ? _c("div", { staticClass: "el-form-item__error" }, [
                        _vm._v(" 请选择一个规则 "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.ruleType == 2,
                      expression: "formInline.ruleType == 2",
                    },
                  ],
                  staticStyle: { width: "700px" },
                  attrs: { label: "对应区域" },
                },
                [
                  _c("el-transfer", {
                    attrs: {
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      props: {
                        key: "areaId",
                        label: "areaName",
                      },
                      "filter-placeholder": "请输入路由类型",
                      data: _vm.AreaList,
                    },
                    model: {
                      value: _vm.selecteArea,
                      callback: function ($$v) {
                        _vm.selecteArea = $$v
                      },
                      expression: "selecteArea",
                    },
                  }),
                  _vm.isSelected
                    ? _c("div", { staticClass: "el-form-item__error" }, [
                        _vm._v(" 请选择一个规则 "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.ruleType == 4,
                      expression: "formInline.ruleType == 4",
                    },
                  ],
                  attrs: { label: "自定义属性说明" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      disabled: _vm.disabled,
                      maxlength: "1000",
                      "show-word-limit": "",
                      placeholder: "请输入自定义属性",
                    },
                    model: {
                      value: _vm.formInline.textarea,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "textarea",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.textarea",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "left",
                "margin-left": "8.5%",
                "margin-top": "40px",
              },
            },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "88px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.submitData },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }